/* tslint:disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: amplitude-js@^8.18.0
 * Tracking Plan Version: 18
 * Build: 1.0.0
 * Runtime: browser:javascript-ampli
 *
 * [View Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/orbit7/Lernpfad/implementation/web)
 */

import amplitude from 'amplitude-js'

/**
 * @typedef BaseEvent
 * @type {object}
 * @property {string} event_type
 * @property {Object.<string, *>} [event_properties]
 */

/**
 * @typedef Plan
 * @type {object}
 * @property {string} [branch]
 * @property {string} [source]
 * @property {string} [version]
 * @property {string} [versionId]
 */

/**
 * Data to be processed by middleware
 * @typedef MiddlewarePayload
 * @type {object}
 * @property {BaseEvent} event
 * @property {MiddlewareExtra} [extra]
 */

/**
 * Function called at the end of each Middleware to run the next middleware in the chain
 * @typedef MiddlewareNext
 * @type {function}
 * @param {MiddlewarePayload} payload
 *
 * @return
 */

/**
 * A function to run on the Event stream (each logEvent call)
 * @typedef Middleware
 * @type {function}
 * @param {MiddlewarePayload} payload The event and extra data being sent
 * @param {MiddlewareNext} next Function to run the next middleware in the chain, not calling next will end the middleware chain
 * @return
 */

/**
 * @typedef LoadClientOptions
 * @type {object}
 * @property {string} [apiKey]
 * @property {Object} [options]
 * @property {AmplitudeClient} [instance]
 */

/**
 * @typedef LoadOptions
 * @type {object}
 * @property {'development'|'production'} [environment]
 * @property {boolean} [disabled]
 * @property {LoadClientOptions} [client]
 */

/**
 * @typedef {Object} EventOptions
 * @type {object}
 */

/**
 * @typedef {Object} IdentifyOptions
 * @type {object}
 */

/**
 * @typedef {Object} GroupOptions
 * @type {object}
 */

/**
 * @typedef {Object} MiddlewareExtra
 * @type {Object.<string, *>}
 */

/**
 * @typedef ApiKey
 * @type {object}
 * @property {string} development
 * @property {string} production
 */
export const ApiKey = {
  development: 'e162f504e7a4f2c225ba49353e96ab89',
  production: 'f0f05da2f8345efbf41328d16d16b5c9',
}

export const SpecialEventType = {
  Identify: 'Identify',
  Group: 'Group',
}

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultOptions = {
  plan: {
    version: '18',
    branch: 'main',
    source: 'web',
    versionId: '0fb4ced4-9c97-4d55-9bbc-3708cf75f802',
  },
}

export class Identify {
  constructor(properties) {
    this.event_type = 'Identify'
    this.event_properties = properties
  }
}

export class Group {
  constructor() {
    this.event_type = 'Group'
  }
}

export class AgbPageViewed {
  constructor() {
    this.event_type = 'Agb Page Viewed'
  }
}

export class AnonymousPageViewed {
  constructor() {
    this.event_type = 'Anonymous Page Viewed'
  }
}

export class DashboardPageViewed {
  constructor() {
    this.event_type = 'Dashboard Page Viewed'
  }
}

export class DataProtectionPageViewed {
  constructor() {
    this.event_type = 'DataProtection Page Viewed'
  }
}

export class HelpPageViewed {
  constructor() {
    this.event_type = 'Help Page Viewed'
  }
}

export class LibraryPageViewed {
  constructor() {
    this.event_type = 'Library Page Viewed'
  }
}

export class Login {
  constructor(properties) {
    this.event_type = 'Login'
    this.event_properties = properties
  }
}

export class Logout {
  constructor(properties) {
    this.event_type = 'Logout'
    this.event_properties = properties
  }
}

export class LpCopied {
  constructor(properties) {
    this.event_type = 'Lp Copied'
    this.event_properties = properties
  }
}

export class LpDeleted {
  constructor() {
    this.event_type = 'Lp Deleted'
  }
}

export class LpLeft {
  constructor(properties) {
    this.event_type = 'Lp Left'
    this.event_properties = properties
  }
}

export class LpPublished {
  constructor(properties) {
    this.event_type = 'Lp Published'
    this.event_properties = properties
  }
}

export class LpUnpublished {
  constructor(properties) {
    this.event_type = 'Lp Unpublished'
    this.event_properties = properties
  }
}

export class LpViewed {
  constructor(properties) {
    this.event_type = 'Lp Viewed'
    this.event_properties = properties
  }
}

export class NewLpCreatedAsAnonymous {
  constructor() {
    this.event_type = 'New Lp Created As Anonymous'
  }
}

export class NotificationsPageViewed {
  constructor(properties) {
    this.event_type = 'Notifications Page Viewed'
    this.event_properties = properties
  }
}

export class ProfilePageViewed {
  constructor(properties) {
    this.event_type = 'Profile Page Viewed'
    this.event_properties = properties
  }
}

export class ProfilesListPageViewed {
  constructor() {
    this.event_type = 'Profiles List Page Viewed'
  }
}

export class Register {
  constructor(properties) {
    this.event_type = 'Register'
    this.event_properties = properties
  }
}

export class SearchUsed {
  constructor(properties) {
    this.event_type = 'Search Used'
    this.event_properties = properties
  }
}

export class SettingsPageViewed {
  constructor() {
    this.event_type = 'Settings Page Viewed'
  }
}

export class SharingLinkCopied {
  constructor(properties) {
    this.event_type = 'Sharing Link Copied'
    this.event_properties = properties
  }
}

export class StudentRemovedFromLp {
  constructor(properties) {
    this.event_type = 'Student Removed From Lp'
    this.event_properties = properties
  }
}

export class TeamPageViewed {
  constructor() {
    this.event_type = 'Team Page Viewed'
  }
}

export class TeilnehmenBtnClicked {
  constructor(properties) {
    this.event_type = 'Teilnehmen Btn Clicked'
    this.event_properties = properties
  }
}

export class VerificationEmailBtnClicked {
  constructor() {
    this.event_type = 'Verification Email Btn Clicked'
  }
}

// prettier-ignore
export class Ampli {
  constructor() {
    /* @type {AmplitudeClient|undefined} */
    this.amplitude = undefined;
    this.disabled = false;
    /* @type {Middleware[]} */
    this.middlewares = [];
  }

  /**
   * @return {AmplitudeClient}
   */
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }

  /**
   * @return {boolean}
   */
  get isLoaded() {
    return this.amplitude != null;
  }

  /**
   * @private
   * @return {boolean}
   */
  isInitializedAndEnabled() {
    if (!this.isLoaded) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   * @param {LoadOptions} [options] Configuration options to initialize the Ampli SDK with.
   */
  load(options) {
    this.disabled = options?.disabled ?? false;

    if (this.isLoaded) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return;
    }

    const env = options?.environment ?? 'development';
    const apiKey = options?.client?.apiKey ?? ApiKey[env];

    if (options?.client?.instance) {
      this.amplitude = options?.client?.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.getInstance();
      this.amplitude?.init(apiKey, undefined, { ...DefaultOptions, ...(options?.client?.options ?? options?.client?.config) });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }
  }

  /**
   * Identify a user and set or update that user's properties.
   *
   * @param {string|undefined} userId The user's id.
   * @param {Object} [properties] The user's properties.
   * @param {string} [properties.email] Property has no description in tracking plan.
   * @param {string} [properties.name] Property has no description in tracking plan.
   * @param {string} [properties.username] Property has no description in tracking plan.
   * @param {IdentifyOptions} [options] Optional event options.
   * @param {MiddlewareExtra} [extra] Extra unstructured data for middleware.
   */
  identify(userId, properties, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const event = {
      event_type: SpecialEventType.Identify,
      event_properties: properties,
      user_id: userId || options?.user_id,
      device_id: options?.device_id
    };
    this.runMiddleware({ event, extra }, payload => {
      const e = payload.event;
      if (e.user_id) {
        this.amplitude.setUserId(e.user_id);
      }
      if (e.device_id) {
        this.amplitude.setDeviceId(e.device_id);
      }
      const ampIdentify = new amplitude.Identify();
      if (e.event_properties != null) {
        for (const [key, value] of Object.entries(e.event_properties)) {
          ampIdentify.set(key, value);
        }
      }
      this.amplitude.identify(
        ampIdentify,
        options?.callback,
        options?.errorCallback
      );
    });
  }

  /**
   * Set Group for the current user
   *
   * @param {String} name
   * @param {String|String[]} value
   * @param {GroupOptions} [options]
   * @param {MiddlewareExtra} [extra]
   */
  setGroup(name, value, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.amplitude?.setGroup(name, value);
  }

  /**
   * Identify a group.
   *
   * @param {string} groupType The group type.
   * @param {string|string[]} groupName The group name.
   * @param {GroupOptions} [options] Options for this groupIdentify call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  groupIdentify(groupType, groupName, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    const event = {
      event_type: SpecialEventType.Group,
      user_id: options?.user_id,
      device_id: options?.device_id
    };
    this.runMiddleware({ event, extra }, payload => {
      const e = payload.event;
      if (e.user_id) {
        this.amplitude.setUserId(e.user_id);
      }
      if (e.device_id) {
        this.amplitude.setDeviceId(e.device_id);
      }
      const amplitudeIdentify = new amplitude.Identify();
      if (e.event_properties != null) {
        for (const [key, value] of Object.entries(e.event_properties)) {
          amplitudeIdentify.set(key, value);
        }
      }
      this.amplitude.groupIdentify(groupType, groupName, amplitudeIdentify, options?.callback);
    });
  }

  /**
   * Track event
   *
   * @param {BaseEvent} event The event to track.
   * @param {EventOptions} [options] Optional event options.
   * @param {MiddlewareExtra} [extra] Extra unstructured data for middleware.
   */
  track(event, options, extra) {
    if (!this.isInitializedAndEnabled()) {
      return;
    }

    this.runMiddleware({ event, extra }, payload => {
      this.amplitude.logEvent(
        payload.event.event_type,
        payload.event.event_properties,
        options?.callback,
        options?.errorCallback,
      );
    });
  }

  /**
   * Agb Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Agb%20Page%20Viewed)
   *
   * equivalent to 'AgbView'
   *
   * Owner: Angélique Hameau
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  agbPageViewed(options, extra) {
    this.track(new AgbPageViewed(), options, extra);
  }

  /**
   * Anonymous Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Anonymous%20Page%20Viewed)
   *
   * allow to count anonymous users landing on lernpfad.ch
   *
   * Owner: Angélique Hameau
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  anonymousPageViewed(options, extra) {
    this.track(new AnonymousPageViewed(), options, extra);
  }

  /**
   * Dashboard Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Dashboard%20Page%20Viewed)
   *
   * equivalent to 'DashboardView'
   *
   * Owner: Angélique Hameau
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  dashboardPageViewed(options, extra) {
    this.track(new DashboardPageViewed(), options, extra);
  }

  /**
   * DataProtection Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/DataProtection%20Page%20Viewed)
   *
   * equivalent to 'DataprotectionView'
   *
   * Owner: Angélique Hameau
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  dataProtectionPageViewed(options, extra) {
    this.track(new DataProtectionPageViewed(), options, extra);
  }

  /**
   * Help Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Help%20Page%20Viewed)
   *
   * equivalent to 'HelpView'
   *
   * Owner: Angélique Hameau
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  helpPageViewed(options, extra) {
    this.track(new HelpPageViewed(), options, extra);
  }

  /**
   * Library Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Library%20Page%20Viewed)
   *
   * Equivalent to 'EnterLibraryView'
   *
   * Owner: Angélique Hameau
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  libraryPageViewed(options, extra) {
    this.track(new LibraryPageViewed(), options, extra);
  }

  /**
   * Login
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Login)
   *
   * When user successfully logged in.
   *
   * Owner: Angélique Hameau
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.username] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  login(properties, options, extra) {
    this.track(new Login(properties), options, extra);
  }

  /**
   * Logout
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Logout)
   *
   * When user logout from the platform
   *
   * Owner: Angélique Hameau
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.username] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  logout(properties, options, extra) {
    this.track(new Logout(properties), options, extra);
  }

  /**
   * Lp Copied
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Lp%20Copied)
   *
   * equivalent to 'CopyPath'
   *
   * Owner: Angélique Hameau
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.lpMode] in which mode is the Lp Page shown:   'admin', 'preview', 'learning'
   * @param {string} properties.lpRef Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  lpCopied(properties, options, extra) {
    this.track(new LpCopied(properties), options, extra);
  }

  /**
   * Lp Deleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Lp%20Deleted)
   *
   * equivalent to 'DeletePath'
   *
   * Owner: Angélique Hameau
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  lpDeleted(options, extra) {
    this.track(new LpDeleted(), options, extra);
  }

  /**
   * Lp Left
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Lp%20Left)
   *
   * equivalent to 'LeavePath'
   *
   * Owner: Angélique Hameau
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.lpRef Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  lpLeft(properties, options, extra) {
    this.track(new LpLeft(properties), options, extra);
  }

  /**
   * Lp Published
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Lp%20Published)
   *
   * equivalent to 'LearnPathPublic' and 'LearnPathOnTeacherProfile'
   *
   * Owner: Angélique Hameau
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.lpRef Property has no description in tracking plan.
   * @param {string} properties.placeOfPublication 'library' or 'teacherProfile'
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  lpPublished(properties, options, extra) {
    this.track(new LpPublished(properties), options, extra);
  }

  /**
   * Lp Unpublished
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Lp%20Unpublished)
   *
   * equivalent to ''LearnPathOnTeachersProfile'
   *
   * Owner: Angélique Hameau
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.lpRef Property has no description in tracking plan.
   * @param {string} properties.placeOfPublication 'library' or 'teacherProfile'
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  lpUnpublished(properties, options, extra) {
    this.track(new LpUnpublished(properties), options, extra);
  }

  /**
   * Lp Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Lp%20Viewed)
   *
   * equivalent to 'PathView'/'EnterPathAdmin'/'EnterPathView'/ 'EnterPreviewView' 
   *  mode: 'admin', 'preview', 'learning'
   *
   * Owner: Angélique Hameau
   *
   * @param {Object} properties The event's properties.
   * @param {string} [properties.lpMode] in which mode is the Lp Page shown:   'admin', 'preview', 'learning'
   * @param {boolean} [properties.lpPubLib] Is the Lp published in the library?
   * @param {boolean} [properties.lpPubProfile] Is the Lp published in the library?
   * @param {string} properties.lpRef Property has no description in tracking plan.
   * @param {boolean} [properties.lpSpeProfile] is the Lp from a special profile in the Profiles List Page?
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  lpViewed(properties, options, extra) {
    this.track(new LpViewed(properties), options, extra);
  }

  /**
   * New Lp Created As Anonymous
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/New%20Lp%20Created%20As%20Anonymous)
   *
   * equivalent to 'NewPath'
   *
   * Owner: Angélique Hameau
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  newLpCreatedAsAnonymous(options, extra) {
    this.track(new NewLpCreatedAsAnonymous(), options, extra);
  }

  /**
   * Notifications Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Notifications%20Page%20Viewed)
   *
   * equivalent to 'BenachrichtigungenView'
   *
   * Owner: Angélique Hameau
   *
   * @param {Object} properties The event's properties.
   * @param {number} properties.nbOfUnreadNotifications Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  notificationsPageViewed(properties, options, extra) {
    this.track(new NotificationsPageViewed(properties), options, extra);
  }

  /**
   * Profile Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Profile%20Page%20Viewed)
   *
   * equivalent to 'EnterProfileView'
   *
   * Owner: Angélique Hameau
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.username] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  profilePageViewed(properties, options, extra) {
    this.track(new ProfilePageViewed(properties), options, extra);
  }

  /**
   * Profiles List Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Profiles%20List%20Page%20Viewed)
   *
   * equivalent to 'EnterProfilesView'
   *
   * Owner: Angélique Hameau
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  profilesListPageViewed(options, extra) {
    this.track(new ProfilesListPageViewed(), options, extra);
  }

  /**
   * Register
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Register)
   *
   * When User successfully registered
   *
   * Owner: Angélique Hameau
   *
   * @param {Object} [properties] The event's properties.
   * @param {string} [properties.username] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  register(properties, options, extra) {
    this.track(new Register(properties), options, extra);
  }

  /**
   * Search Used
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Search%20Used)
   *
   * equivalent to 'UseSearch' and 'UserSearchResult'
   *
   * Owner: Angélique Hameau
   *
   * @param {Object} properties The event's properties.
   * @param {number} properties.nbOfResults Property has no description in tracking plan.
   * @param {string} properties.termSearched Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  searchUsed(properties, options, extra) {
    this.track(new SearchUsed(properties), options, extra);
  }

  /**
   * Settings Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Settings%20Page%20Viewed)
   *
   * equivalent to 'SettingsView'
   *
   * Owner: Angélique Hameau
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  settingsPageViewed(options, extra) {
    this.track(new SettingsPageViewed(), options, extra);
  }

  /**
   * Sharing Link Copied
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Sharing%20Link%20Copied)
   *
   * equivalent to 'CopyByButton' / equivalent to 'CopyEinladen'/ equivalent to 'CopyVorschau'
   *
   * Owner: Angélique Hameau
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.lpRef Property has no description in tracking plan.
   * @param {string} properties.sharingMode 'preview' or 'invitation'
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  sharingLinkCopied(properties, options, extra) {
    this.track(new SharingLinkCopied(properties), options, extra);
  }

  /**
   * Student Removed From Lp
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Student%20Removed%20From%20Lp)
   *
   * equivalent to 'RemoveFromPath'
   *
   * Owner: Angélique Hameau
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.lpRef Property has no description in tracking plan.
   * @param {string} [properties.username] Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  studentRemovedFromLp(properties, options, extra) {
    this.track(new StudentRemovedFromLp(properties), options, extra);
  }

  /**
   * Team Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Team%20Page%20Viewed)
   *
   * equivalent to 'TeamView'
   *
   * Owner: Angélique Hameau
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  teamPageViewed(options, extra) {
    this.track(new TeamPageViewed(), options, extra);
  }

  /**
   * Teilnehmen Btn Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Teilnehmen%20Btn%20Clicked)
   *
   * equivalent to 'clickTeilnehmen'
   *
   * Owner: Angélique Hameau
   *
   * @param {Object} properties The event's properties.
   * @param {string} properties.lpRef Property has no description in tracking plan.
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  teilnehmenBtnClicked(properties, options, extra) {
    this.track(new TeilnehmenBtnClicked(properties), options, extra);
  }

  /**
   * Verification Email Btn Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/orbit7/Lernpfad/events/main/latest/Verification%20Email%20Btn%20Clicked)
   *
   * equivalent to 'btnVerificationEmail'
   *
   * Owner: Angélique Hameau
   *
   * @param {EventOptions} [options] Options for this track call.
   * @param {MiddlewareExtra} [extra] Extra untyped parameters for use in middleware.
   */
  verificationEmailBtnClicked(options, extra) {
    this.track(new VerificationEmailBtnClicked(), options, extra);
  }

  /**
   * Add new middleware to end of chain
   *
   * @param {Middleware} middleware
   */
  addEventMiddleware(middleware) {
    this.middlewares.push(middleware);
  }

  /**
   * Runs all middleware
   *
   * @param {MiddlewarePayload} payload
   * @param {MiddlewareNext} next The method to run after all middleware.
   *
   * @protected
   */
  runMiddleware(payload, next) {
    let curMiddlewareIndex = -1;
    const middlewareCount = this.middlewares.length;

    const middlewareNext = curPayload => {
      curMiddlewareIndex += 1;
      if (curMiddlewareIndex < middlewareCount) {
        this.middlewares[curMiddlewareIndex](curPayload, _next);
      } else {
        next(curPayload);
      }
    };

    const _next = middlewareCount > 0 ? middlewareNext : next;

    _next(payload);
  }
}

export const ampli = new Ampli()
