<template>
  <div>
    <b-navbar
      toggleable="md"
      type="dark"
      variant="primary"
    >
      <div class="container pr-0 pl-sm-0 pl-lg-3">
        <b-navbar-brand :to="localePath('/')">
          <logoLernpfadWhite class="lernpfad-logo" />
        </b-navbar-brand>
        <div
          v-if="$store.state.user"
          v-b-toggle.sidebar
          class="d-md-none sidebar-toggle"
        >
          <profile-picture
            :with-indicator="userHasNotifications"
            :user="$store.state.user"
            class="sidebar-toggle-profile-pic"
          />
        </div>
        <div
          v-else
          v-b-toggle.sidebar
          class="d-md-none sidebar-toggle"
        >
          <burger-svg />
        </div>
        <b-collapse
          id="nav-collapse"
          is-nav
        >
          <b-navbar-nav class="ml-auto d-md-flex">
            <b-nav-item
              :to="localePath('/bibliothek')"
              right
              class="library-button"
            >
              <librarySvg class="library-logo" />
              <span>{{ $t('Bibliothek') }}</span>
            </b-nav-item>
            <b-nav-item
              :to="localePath('/profile')"
              right
              class="library-button"
            >
              <accountGroup class="people-logo" />
              <span>{{ $t('Profile') }}</span>
            </b-nav-item>
            <LanguageSwitcher class="langSwitcher" />
            <b-navbar-nav
              v-if="userReady"
              right
            >
              <b-nav-item-dropdown
                v-if="$store.state.user"
                class="navbar-dropdown"
                data-cy="userMenu"
                no-caret
                right
              >
                <template slot="button-content">
                  <div class="user-menu-button">
                    <profile-picture
                      :user="$store.state.user"
                      :with-indicator="userHasNotifications"
                    />
                    <span class="user-menu-username">
                      {{ $store.state.user.name }}
                    </span>
                  </div>
                </template>
                <b-dropdown-item :to="localePath('/benachrichtigungen')">
                  <notificationIconWithBadgeSvg v-if="userHasNotifications" />
                  <notificationIconSvg v-else />
                  {{ $t('Benachrichtigungen') }}
                </b-dropdown-item>
                <b-dropdown-item
                  :to="localePath(`/profil/${$store.getters.username}`)"
                >
                  <copySvg />
                  {{ $t('Profil') }}
                </b-dropdown-item>
                <b-dropdown-item :to="localePath('/einstellungen')">
                  <settingsIconSvg />
                  {{ $t('Einstellungen') }}
                </b-dropdown-item>
                <b-dropdown-item @click="onLogout">
                  <signOutIconSvg />
                  {{ $t('Abmelden') }}
                </b-dropdown-item>
              </b-nav-item-dropdown>
              <b-nav-item
                v-if="!$store.state.user"
                id="nav-register"
                :to="localePath('/registrieren')"
              >
                {{ $t('Registrieren') }}
              </b-nav-item>
              <b-nav-item
                v-if="!$store.state.user"
                id="nav-sign-in"
                :to="localePath(`/login?next=${$route.path}`)"
              >
                {{ $t('Anmelden') }}
              </b-nav-item>
            </b-navbar-nav>
          </b-navbar-nav>
        </b-collapse>
      </div>
    </b-navbar>
    <b-sidebar
      v-if="userReady && $store.state.user"
      id="sidebar"
      class="d-md-none sidebar-logged-in"
      right
    >
      <div class="py-2">
        <div class="mobile-nav-username">
          <profile-picture
            :with-indicator="false"
            :user="$store.state.user"
          />
          <span class="user-menu-username">{{ $store.state.user.name }}</span>
        </div>
        <b-link
          class="sidebar-link"
          :to="localePath('/benachrichtigungen')"
        >
          <notificationIconWithBadgeSvg v-if="userHasNotifications" />
          <notificationIconSvg v-else />
          <span class="sidebar-link-text">{{ $t('Benachrichtigungen') }}</span>
        </b-link>
        <b-link
          class="sidebar-link"
          :to="localePath(`/profil/${$store.getters.username}`)"
        >
          <copySvg />
          <span class="sidebar-link-text">{{ $t('Profil') }}</span>
        </b-link>
        <b-link
          class="sidebar-link"
          :to="localePath('/bibliothek')"
        >
          <libraryDarkSvg />
          <span class="sidebar-link-text">{{ $t('Bibliothek') }}</span>
        </b-link>
        <b-link
          class="sidebar-link"
          :to="localePath('/profile')"
        >
          <accountGroup class="mobile-people-logo" />
          <span class="sidebar-link-text">{{ $t('Profile') }}</span>
        </b-link>
        <b-link
          class="sidebar-link"
          :to="localePath('/einstellungen')"
        >
          <settingsIconSvg />
          <span class="sidebar-link-text">{{ $t('Einstellungen') }}</span>
        </b-link>
        <b-link
          class="sidebar-link"
          @click="onLogout"
        >
          <signOutIconSvg />
          <span class="sidebar-link-text">{{ $t('Abmelden') }}</span>
        </b-link>
        <b-link class="sidebar-link">
          <LanguageSwitcherList class="ml-4 pl-1" />
        </b-link>
      </div>
    </b-sidebar>
    <b-sidebar
      v-else
      id="sidebar"
      class="d-md-none sidebar-anon"
      right
    >
      <b-link
        class="sidebar-link"
        :to="localePath('/bibliothek')"
      >
        <libraryDarkSvg />
        <span class="sidebar-link-text">{{ $t('Bibliothek') }}</span>
      </b-link>
      <b-link
        class="sidebar-link"
        :to="localePath('/profile')"
      >
        <accountGroup class="mobile-people-logo" />
        <span class="sidebar-link-text">{{ $t('Profile') }}</span>
      </b-link>
      <b-link
        class="sidebar-link mobile-login-button"
        :to="localePath(`/login?next=${$route.path}`)"
      >
        <sign-in-icon-svg class="dark-login-button" />
        <span id="logged-out-mobile-sign-in">{{ $t('Anmelden') }}</span>
      </b-link>
      <b-link
        class="sidebar-link"
        :to="localePath('/registrieren')"
      >
        <editSvg />
        {{ $t('Registrieren') }}
      </b-link>
      <b-link class="sidebar-link">
        <LanguageSwitcherList class="ml-4 pl-1" />
      </b-link>
    </b-sidebar>
  </div>
</template>

<script>
import logoLernpfadWhite from '../../assets/images/logoLernpfadWhite.svg'
import notificationIconSvg from '../../assets/images/notificationIcon.svg'
import notificationIconWithBadgeSvg from '../../assets/images/notificationIconWithBadge.svg'
import settingsIconSvg from '../../assets/images/settings.svg'
import signOutIconSvg from '../../assets/images/signOut.svg'
import signInIconSvg from '../../assets/images/signIn.svg'
import librarySvg from '../../assets/images/library.svg'
import libraryDarkSvg from '../../assets/images/libraryDark.svg'
import copySvg from '../../assets/images/copy.svg'
import burgerSvg from '../../assets/images/burger.svg'
import editSvg from '../../assets/images/edit.svg'
import accountGroup from '../../assets/images/account-group.svg'
import LanguageSwitcher from './LanguageSwitcher.vue'
import LanguageSwitcherList from './LanguageSwitcherList.vue'
import ProfilePicture from './ProfilePicture'
import { deleteCookie } from '@/utils/cookie'
import { ampli } from '@/mixins/Amplitude'

export default {
  components: {
    logoLernpfadWhite,
    librarySvg,
    libraryDarkSvg,
    ProfilePicture,
    copySvg,
    burgerSvg,
    editSvg,
    notificationIconSvg,
    notificationIconWithBadgeSvg,
    settingsIconSvg,
    signOutIconSvg,
    signInIconSvg,
    LanguageSwitcher,
    LanguageSwitcherList,
    accountGroup,
  },

  props: {
    userReady: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    userHasNotifications() {
      return this.$store.state.user.unreadNotificationsCount > 0
    },
    userInitials() {
      console.log(this.$store.state.user.name)
      const out = this.$store.state.user.name
        .match(/\b\w/g)
        .join('')
        .toUpperCase()
      if (out.length > 2) {
        return out.substring(0, 2)
      } else {
        return out
      }
    },
  },

  methods: {
    onLogout() {
      ampli.logout({
        username: this.$store.state.user.username,
      })
      deleteCookie('apollo-token')
      deleteCookie('sessionid')
      window.location = this.localePath('/')
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
@import '../../assets/style/vars';

[data-initials]:before {
  background: #deeb75;
  color: white;
  opacity: 1;
  content: attr(data-initials);
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 0.5em;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-bottom: 2px;
  font-size: 13px;
}

.navbar-toggler {
  border: none;
}

.navbar {
  flex-direction: row;
}
.lernpfad-logo {
  padding-bottom: 2px;
  padding-right: 40px;
  position: relative;
  bottom: 3px;
}

.library-button {
  position: relative;
  top: 1px;
  padding-right: 15px;
}

.library-logo {
  padding-bottom: 5px;
}

.people-logo {
  fill: white;
  padding-top: 3px;
}

.navbar-dropdown {
  z-index: 99999;
}

.user-menu-button {
  display: flex;
  height: 36px;
}

.user-menu-username {
  display: inline-block;
  padding: 3px 3px 3px 6px;
}

.sidebar-toggle {
  position: absolute;
  top: 17px;
  right: 17px;
}

.mobile-nav-username {
  background-color: $primary;
  color: white;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
}

.sidebar-link {
  color: $primary;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  border-bottom: 1px solid #cbcbcb;
}

.sidebar-link-text {
  position: relative;
  bottom: -2px;
}

.mobile-login-button {
  background-color: $primary;
  color: white;
}
.mobile-login-button:hover {
  color: white;
}

.mobile-people-logo {
  padding: 7px 0 0 7px;
}

.dark-login-button {
  filter: brightness(0) invert(1);
  margin-left: -2px;
}

#logged-out-mobile-sign-in {
  margin-left: -2px;
}

#nav-sign-in {
  position: relative;
  top: 3px;
  padding-right: 15px;
}

#nav-register {
  position: relative;
  top: 3px;
}
</style>
