<template>
  <footer>
    <b-container>
      <b-row class="justify-content-lg-center prime-footer-row">
        <b-col
          cols="4"
          lg="2"
        >
          <a
            href="mailto:info@lernpfad.ch"
            class="footerItem"
          >
            <mail-svg />
            {{ $t('Kontakt') }}
          </a>
        </b-col>
        <b-col
          cols="4"
          lg="2"
        >
          <nuxt-link
            :to="localePath('/hilfe')"
            class="footerItem"
          >
            <help-svg />
            {{ $t('Hilfe') }}
          </nuxt-link>
        </b-col>
        <b-col
          cols="4"
          lg="2"
        >
          <div class="lang_option">
            <LanguageSwitcher />
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="3"
          offset-lg="3"
        >
          {{ $t('Folge uns') }}
          <a
            href="https://www.facebook.com/lernpfad"
            aria-label="Facebook"
            rel="noreferrer"
            target="_blank"
          >
            <facebook-svg />
          </a>
          <a
            href="https://www.linkedin.com/company/lernpfad/"
            aria-label="LinkedIn"
            rel="noreferrer"
            target="_blank"
          >
            <linked-in-svg />
          </a>
          <a
            href="https://www.instagram.com/lernpfad/"
            aria-label="Instagram"
            rel="noreferrer"
            target="_blank"
          >
            <instagram-svg />
          </a>
        </b-col>
      </b-row>
      <b-row class="justify-content-lg">
        <b-col
          cols="12"
          lg="6"
        >
          <nuxt-link
            :to="localePath('/impressum')"
            class="footerItem"
          >
            {{ $t('Impressum') }}
          </nuxt-link>
          <nuxt-link
            :to="localePath('/datenschutz')"
            class="footerItem footer-small-link"
          >
            {{ $t('Datenschutz') }}
          </nuxt-link>
          <nuxt-link
            :to="localePath('/agb')"
            class="footerItem footer-small-link"
          >
            {{ $t('AGB') }}
          </nuxt-link>
          <nuxt-link
            :to="localePath('/team')"
            class="footerItem footer-small-link"
          >
            {{ $t('Über uns') }}
          </nuxt-link>
          <nuxt-link
            :to="localePath('/raisenow')"
            class="footerItem footer-small-link"
          >
            {{ $t('Unterstütze Lernpfad') }}
          </nuxt-link>
        </b-col>
        <b-col
          cols="0"
          lg="3"
        />
        <b-col
          cols="12"
          lg="3"
        >
          <div class="footerItem footer-copyright">
            ©
            <a
              href="https://www.lernetz.ch/"
              rel="noopener"
              target="_blank"
            >
              LerNetz AG
            </a>
            &
            <a
              href="https://orbit7.ch/"
              rel="noopener"
              target="_blank"
            >
              orbit7 gmbh
            </a>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <cookie-law
      :button-text="$t('Ich bin einverstanden!')"
      :button-link="localePath('/datenschutz')"
      :button-link-text="$t('Datenschutz')"
    >
      <div
        slot="message"
        class="text-center"
      >
        {{
          $t(
            'Diese Seite verwendet Cookies, unter anderem damit du dich nicht jedesmal einloggen musst'
          )
        }}. {{ $t('Mehr erfährst du unter Datenschutz') }}.
      </div>
    </cookie-law>
  </footer>
</template>

<script>
import CookieLaw from 'vue-cookie-law'
import mailSvg from '../../assets/images/mail.svg'
import facebookSvg from '../../assets/images/facebook.svg'
import linkedInSvg from '../../assets/images/linkedin.svg'
import instagramSvg from '../../assets/images/Instagram.svg'
import helpSvg from '../../assets/images/help.svg'
import LanguageSwitcher from './LanguageSwitcher.vue'

export default {
  components: {
    mailSvg,
    facebookSvg,
    linkedInSvg,
    instagramSvg,
    CookieLaw,
    helpSvg,
    LanguageSwitcher,
  },
}
</script>

<style lang="scss">
@import '../../assets/style/vars';

.Cookie--base .Cookie__button {
  background: $darkblue;
  color: white;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.Cookie--base .Cookie__button:hover {
  background: $darkblue-active;
}
</style>

<style lang="scss" scoped>
@import '../../assets/style/vars';
@import '../../assets/style/utils';

@mixin footerText {
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 0.22px;
  @include fontSizeLineHeight(15px, 15px);
}

footer {
  background-color: $primary;
  // border-top: 10px $secondary solid;
  padding: calculateRem(26px) 0 calculateRem(46px) 0;

  @include footerText;

  a {
    @include footerText;
    text-decoration: none;
  }

  .prime-footer-row {
    margin-bottom: 20px;
    @include fontSizeLineHeight(15px, 25px);
    a {
      @include fontSizeLineHeight(15px, 25px);
    }
  }

  .footer-copyright {
    opacity: 0.5;
  }

  .footer-small-link {
    margin-left: 15px;
  }
}

@media (max-width: map-get($grid-breakpoints, lg)) {
  .footer-copyright {
    margin-top: 10px;
  }

  .footerItem {
    padding: calculateRem(5px) 0;
  }
}
</style>
