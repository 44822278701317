export default ({ app }) => {
  app.router.beforeEach((to, from, next) => {
    if (app.store.state.isDirty) {
      if (
        confirm(
          'Du hast ungespeicherte Änderungen! Willst du die Seite verlassen?'
        )
      ) {
        app.store.commit('markClean')
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  })
}
