// fixme: refactor that nasty shit. it should read out the things from env vars

const sentryDSN = 'https://1f609c87b3de4f919f69236a9dd0f940@sentry.io/1816220'

const prodConfig = {
  baseUrl: 'https://lernpfad.ch',
  apiUrl: 'https://lernpfad.ch',
  sentryDsn: sentryDSN,
  sentryEnvironment: 'Prod',

  featureFlags: {},
}

const stageConfig = {
  baseUrl: 'https://stage.lernpfad.ch',
  apiUrl: 'https://stage.lernpfad.ch',
  sentryDsn: sentryDSN,
  sentryEnvironment: 'Stage',

  featureFlags: {},
}

const localConfig = {
  baseUrl: 'http://localhost:3000',
  apiUrl: 'http://localhost:8000',
  sentryDsn: sentryDSN,
  sentryEnvironment: 'Local',
  sentryDebug: false,
  sentryLogLocal: true,

  featureFlags: {},
}

// That`s a shameful way to do configs
const config = (() => {
  // ssr?
  if (typeof window === 'undefined') {
    console.log(`**** process.env.NODE_ENV: ${process.env.NODE_ENV} ****`)
    if (process.env.NODE_ENV === 'stage') {
      return stageConfig
    } else if (process.env.NODE_ENV !== 'production') {
      return localConfig
    }
    return prodConfig
  }

  const host = window.location.hostname

  // return prod as soon as possible
  if (host === 'lernpfad.ch') {
    return prodConfig
  } else if (host === 'stage.lernpfad.ch') {
    return stageConfig
  } else if (host === 'localhost' || isPrivateIP(host)) {
    return localConfig
  } else {
    return {
      baseUrl: 'https://' + host,
      apiUrl: 'https://' + host,
      sentryDsn: sentryDSN,
      sentryEnvironment: 'Review',
      featureFlags: {},
    }
  }
})()

config.fullApiUrl = (path) => {
  if (isWidget()) {
    const apiUrl = config.widgetOrigin
    return `${apiUrl}${path}`
  }

  if (path && path.indexOf('data:') === 0) {
    return path
  }
  return `${config.apiUrl}${path}`
}

function isPrivateIP(ip) {
  const parts = ip.split('.')
  return (
    parts[0] === '10' ||
    (parts[0] === '172' &&
      parseInt(parts[1], 10) >= 16 &&
      parseInt(parts[1], 10) <= 31) ||
    (parts[0] === '192' && parts[1] === '168')
  )
}

function isWidget() {
  const listNotWidgetLocal = ['localhost:3000', 'localhost:8000']

  if (
    listNotWidgetLocal.includes(getDomainName(window.location.host)) ||
    window.location.host.includes('lernpfad')
  ) {
    return false
  } else {
    return true
  }
}

function getDomainName(hostName) {
  return hostName.substring(
    hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1
  )
}

export default config
