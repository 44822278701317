<template>
  <div class="relative">
    <button
      href="#"
      class="flex items-center select"
      @click="toggleVisibility"
      @keydown.space.exact.prevent="toggleVisibility"
      @keydown.esc.exact="hideDropdown"
      @keydown.shift.tab="hideDropdown"
      @keydown.up.exact.prevent="startArrowKeys"
      @keydown.down.exact.prevent="startArrowKeys"
    >
      <span class="">{{ $i18n.locale.toUpperCase() }}</span>
      <svg
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          class="heroicon-ui"
          d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
        />
      </svg>
    </button>
    <transition name="dropdown-fade">
      <ul
        v-if="isVisible"
        ref="dropdown"
        class="parent position-absolute"
      >
        <li
          v-for="locale in $i18n.locales"
          :key="locale.code"
          @click="
            toggleVisibility()
            updateProfile(locale.code)
          "
        >
          <nuxt-link
            :to="switchLocalePath(locale.code)"
            class="item"
            @keydown.up.exact.prevent=""
            @keydown.tab.exact="focusNext(false)"
            @keydown.down.exact.prevent="focusNext(true)"
            @keydown.esc.exact="hideDropdown"
          >
            <span class="item">{{ locale.name }}</span>
          </nuxt-link>
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
import UserUpdateLanguageGql from '../../gql/UserUpdateLanguage.gql'

export default {
  data() {
    return {
      isVisible: false,
      focusedIndex: 0,
    }
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible
    },
    hideDropdown() {
      this.isVisible = false
      this.focusedIndex = 0
    },
    startArrowKeys() {
      if (this.isVisible) {
        // this.$refs.account.focus()
        this.$refs.dropdown.children[0].children[0].focus()
      }
    },
    focusPrevious(isArrowKey) {
      this.focusedIndex = this.focusedIndex - 1
      if (isArrowKey) {
        this.focusItem()
      }
    },
    focusNext(isArrowKey) {
      this.focusedIndex = this.focusedIndex + 1
      if (isArrowKey) {
        this.focusItem()
      }
    },
    focusItem() {
      this.$refs.dropdown.children[this.focusedIndex].children[0].focus()
    },
    async updateProfile(locale) {
      if (this.$store.state.user) {
        const vars = { language: locale }
        await this.$apollo.mutate({
          mutation: UserUpdateLanguageGql,
          variables: vars,
        })
        this.$store.commit('setUser', { ...this.$store.state.user, ...vars })
      }
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
@import '../../assets/style/vars';

.dropdown-fade-enter-active,
.dropdown-fade-leave-active {
  transition: all 0.1s ease-in-out;
}

.dropdown-fade-enter,
.dropdown-fade-leave-to {
  opacity: 0;
  transform: translateY(-12px);
}

.navbar-nav .select {
  color: rgba(255, 255, 255, 0.5);
  padding: 0.7rem 0.5rem;
}

footer .select {
  color: white;
  padding: 0.3rem 0.5rem;
}

.select {
  background: $darkblue;
  border: none;
}

.parent {
  background-color: $darkblue;
  z-index: 100;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    &:hover {
      // background: blue;
      .item {
        color: white;
      }
    }

    .item {
      color: rgba(255, 255, 255, 0.5);
      padding: 0.3rem 1rem 0.3rem 0.3rem;
      text-decoration: none;
    }
  }
}
</style>
