<template>
  <div>
    <VueGallery
      :images="files"
      :index="$store.state.galleryIndex"
      @close="$store.commit('closeGallery')"
    />
  </div>
</template>

<script>
import VueGallery from 'vue-gallery'

export default {
  components: {
    VueGallery,
  },

  computed: {
    files() {
      return this.$store.state.galleryFiles.map((d) => ({
        title: this.galleryTitle(d.originalFileName),
        href: this.config.fullApiUrl(d.fileUrl),
        type: d.fileType === 'image' ? 'image' : 'video/mp4',
      }))
    },
  },

  methods: {
    // generate abbreviated url that's length depends on the width of the device
    galleryTitle(filename) {
      const maxLength = Math.floor(window.innerWidth / 15)
      if (filename.length > maxLength) {
        const ext = filename.split('.').pop()
        const nameWithoutExt = filename.replace(ext, '')

        const part1 = nameWithoutExt.substring(0, Math.floor(maxLength / 2))
        const part2 = nameWithoutExt.substring(
          nameWithoutExt.length - Math.floor(maxLength / 2)
        )
        return part1 + ' … ' + part2 + ext
      } else {
        return filename
      }
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/style/vars';
@import '../../assets/style/utils';

.blueimp-gallery {
  .title {
    font-family: $font-family-sans-serif !important;
    font-weight: 400;
    display: block !important;
  }

  .close {
    color: #fff !important;
    font-family: $font-family-sans-serif !important;
    display: block !important;

    &:hover {
      color: #fff !important;
    }
  }

  .next,
  .prev {
    text-shadow: none !important;
    color: $primary !important;
    border: none;
    opacity: 1;
    background-color: $secondary;
    display: block !important;

    &:hover {
      color: $primary !important;
    }
  }

  .slides > .slide > .slide-content {
    max-width: 90%;
    max-height: 85%;
  }
}
</style>
