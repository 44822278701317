<template>
  <div class="defaultLayout">
    <Gallery />
    <Navbar :user-ready="userReady" />
    <Alert
      :message="$store.state.alertMessage"
      :count-down="$store.state.alertCountDown"
      :variant="$store.state.alertVariant"
    />
    <div class="defaultLayoutContent">
      <nuxt v-if="userReady" />
      <div v-else>
        <ContentSpinner />
      </div>
    </div>
    <TheFooter />
  </div>
</template>

<script>
import Navbar from '../components/ui/Navbar'
import TheFooter from '../components/ui/TheFooter'
import UserCurrentGql from '../gql/UserCurrent.gql'
import ContentSpinner from '../components/ui/ContentSpinner'
import Gallery from '../components/ui/Gallery'
import Alert from '../components/ui/Alert'

export default {
  components: {
    Navbar,
    TheFooter,
    ContentSpinner,
    Gallery,
    Alert,
  },

  data() {
    return {
      userReady: false,
    }
  },

  methods: {
    clearAlert() {
      this.$store.commit('setAlert', { message: undefined, countDown: 0 })
    },
  },

  apollo: {
    me: {
      query: UserCurrentGql,
      result({ data }) {
        if (data && data.me) {
          this.$store.commit('setUser', Object.assign({}, data.me))
        } else {
          this.$store.commit('setUser', undefined)
        }
        this.userReady = true
      },
      pollInterval: 10000, // poll every 10s
    },
  },
}
</script>

<style
  scoped
  lang="scss"
>
.defaultLayout {
}

.defaultLayoutContent {
  min-height: 100vh;
}
</style>
