var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.user)?_c('div',{staticClass:"position-relative"},[_c('div',{staticClass:"profile-pic-container",class:{ filtershadow: _vm.withshadow, 'border-blue': _vm.user.picture },style:({
        width: (_vm.size + "px"),
        height: (_vm.size + "px"),
        clipPath: "circle()",
      })},[(_vm.user.picture)?_c('img',{staticClass:"profile-pic-image profile-avatar",style:({ clipPath: "circle(47% at 50%)" }),attrs:{"alt":"Profilbild","src":("" + (_vm.config.fullApiUrl(_vm.imageApiRequest)))}}):(_vm.user.name && _vm.defaultInitial)?_c('span',{staticClass:"profile-avatar",style:({
          width: (_vm.size + "px"),
          height: (_vm.size + "px"),
          lineHeight: (_vm.size + "px"),
          clipPath: "circle(47% at 50%)",
        }),attrs:{"data-initials":_vm.userInitials}}):_c('img',{staticClass:"profile-avatar",class:{ filtershadow: _vm.withshadow },style:({ clipPath: "circle()" }),attrs:{"alt":"Profilbild","src":require("assets/images/dummyProfile.png")}})]),_vm._v(" "),(_vm.withIndicator)?_c('redDotSvg',{staticClass:"profile-pic-indicator"}):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }