import { createHttpLink } from 'apollo-link-http'
import config from '../utils/config'

export default (ctx) => {
  const httpLink = createHttpLink({
    uri: config.fullApiUrl('/api'),
  })

  return {
    link: httpLink,
    httpLinkOptions: {
      credentials: 'same-origin',
    },
    // You can use `wss` for secure connection (recommended in production)
    // Use `null` to disable subscriptions
    // wsEndpoint: 'ws://localhost:4000', // optional
    // LocalStorage token
    tokenName: 'apollo-token', // optional
    // Enable Automatic Query persisting with Apollo Engine
    persisting: false, // Optional
    // Use websockets for everything (no HTTP)
    // You need to pass a `wsEndpoint` for this to work
    websocketsOnly: false, // Optional
    defaultHttpLink: false, // this should do the trick
  }
}
