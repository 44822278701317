<template>
  <div class="relative">
    <ul class="parent">
      <li
        v-for="(locale, index) in $i18n.locales"
        :key="locale.code"
      >
        <nuxt-link
          :to="switchLocalePath(locale.code)"
          class="item"
        >
          <span v-if="index !== 0">/</span>
          <span class="item">{{ locale.code | capitalize }}</span>
        </nuxt-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  methods: {},
}
</script>

<style
  lang="scss"
  scoped
>
@import '../../assets/style/vars';

.parent {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;

  li {
    .item {
      padding-right: 0.3rem;
      text-decoration: none;
    }
  }
}
</style>
