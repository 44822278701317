<template>
  <div class="spinner-container content">
    <b-spinner class="spinner" />
  </div>
</template>

<script>
export default {}
</script>

<style
  scoped
  lang="scss"
>
.spinner-container {
  text-align: center;
}
</style>
