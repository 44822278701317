import Vue from 'vue'
import moment from 'moment'

export default () => {
  Vue.filter('capitalize', function (value) {
    if (!value) {
      return ''
    } else {
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  })
  Vue.filter('formatDate', function (value) {
    if (value) {
      return moment(String(value)).format('MM/DD/YY HH:MM')
    }
  })
  Vue.filter('formatHour', function (value) {
    if (value) {
      return moment(String(value)).format('HH:MM')
    }
  })
}
