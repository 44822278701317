import Vue from 'vue'
import TextareaAutosize from 'vue-textarea-autosize'
import VueMarkdown from 'vue-remarkdown'
import VueClipboards from 'vue-clipboards'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import VueLazyload from 'vue-lazyload'
import config from '../utils/config'
import AppLink from '../components/AppLink.vue'

Vue.use(TextareaAutosize)
Vue.component('VueMarkdown', VueMarkdown)
Vue.component('AppLink', AppLink)
Vue.use(VueClipboards)
Vue.use(VueLazyload, {
  lazyComponent: true,
})

Sentry.init({
  dsn: config.sentryDsn,
  environment: config.sentryEnvironment,
  integrations: [new Integrations.Vue({ Vue, attachProps: true })],
  debug: !!config.sentryDebug,
  beforeSend: (event, hint) => {
    if (config.sentryLogLocal) {
      console.error(hint.originalException || hint.syntheticException || event)
      return null // won't send anything to sentry
    }
    return event
  },
})

Vue.mixin({
  data() {
    return {
      get config() {
        return config
      },
    }
  },
})
