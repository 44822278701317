<template>
  <div>
    <section
      v-if="error.statusCode === 404"
      class="container content"
    >
      <h1>{{ $t('Ups, Seite nicht gefunden') }}</h1>

      <div class="my-4">
        <p class="mb-2">
          {{
            $t(
              'Möglicherweise, ist der Link veraltet und die Seite wurde gelöscht.'
            )
          }}
        </p>
        <i18n path="text_error_404">
          <template #a_to_mail>
            <a href="mailto:info@lernpfad.ch">
              {{ $t('kontaktiere uns bitte') }}
            </a>
            .
          </template>
        </i18n>
      </div>

      <nuxt-link
        :to="localePath('/')"
        class="btn btn-primary"
      >
        {{ $t('Zurück zur Startseite') }}
      </nuxt-link>
    </section>

    <section
      v-else
      class="container content"
    >
      <h1>{{ $t('Ups, da haben wir einen Fehler') }}</h1>

      <div class="my-4">
        <i18n path="text_error">
          <template #a_to_mail>
            <a href="mailto:info@lernpfad.ch">
              {{ $t('kontaktiere uns bitte') }}
            </a>
            .
          </template>
        </i18n>
      </div>

      <nuxt-link
        :to="localePath('/')"
        class="btn btn-primary"
      >
        {{ $t('Zurück zur Startseite') }}
      </nuxt-link>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
}
</script>
