export const state = () => ({
  isDirty: false,
  tmpLearnPath: undefined,
  copyLearnPath: undefined,
  galleryFiles: [],
  galleryIndex: undefined,
  user: undefined,
  alertMessage: undefined,
  alertCountDown: undefined,
})

export const getters = {
  username(state) {
    return state.user ? state.user.username : ''
  },
}

export const mutations = {
  markDirty(state) {
    state.isDirty = true
  },

  markClean(state) {
    state.isDirty = false
  },

  setTmpLearnPath(state, tmpLearnPath) {
    state.tmpLearnPath = tmpLearnPath
  },

  clearTmpLearnPath(state) {
    state.tmpLearnPath = undefined
  },

  setCopyLearnPath(state, copyLearnPath) {
    state.copyLearnPath = copyLearnPath
  },

  clearCopyLearnPath(state) {
    state.copyLearnPath = undefined
  },

  openGallery(state, { files, index = 0 }) {
    state.galleryFiles = files
    state.galleryIndex = index
  },

  closeGallery(state) {
    state.galleryIndex = null
  },

  setUser(state, user) {
    state.user = user
  },

  setAlert(state, { message, countDown, variant = 'success' }) {
    state.alertMessage = message
    state.alertCountDown = countDown
    state.alertVariant = variant
  },
}
