<template>
  <div>
    <b-alert
      :show="countDown"
      dismissible
      :variant="variant"
      @dismissed="clearAlert"
    >
      <p>{{ message }}</p>
    </b-alert>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    message: {
      type: String,
      required: false,
      default: '',
    },
    countDown: {
      type: Number,
      required: false,
      default: 0,
    },
    variant: {
      type: String,
      required: false,
      default: 'success',
    },
  },
  methods: {
    clearAlert() {
      this.$store.commit('setAlert', {
        message: undefined,
        countDown: 0,
      })
    },
  },
}
</script>

<style lang="scss">
@import '../../assets/style/vars';
@import '../../assets/style/utils';

.alert {
  z-index: 99999;
  position: fixed;
  top: 10px;
  margin: auto;
  left: 50%;
  transform: translateX(-50%);
  width: 400px;
  max-width: 92%;
}
</style>
