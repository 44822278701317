<template>
  <div>
    <div
      v-if="user"
      class="position-relative"
    >
      <div
        class="profile-pic-container"
        :class="{ filtershadow: withshadow, 'border-blue': user.picture }"
        :style="{
          width: `${size}px`,
          height: `${size}px`,
          clipPath: `circle()`,
        }"
      >
        <img
          v-if="user.picture"
          alt="Profilbild"
          class="profile-pic-image profile-avatar"
          :src="`${config.fullApiUrl(imageApiRequest)}`"
          :style="{ clipPath: `circle(47% at 50%)` }"
        />
        <span
          v-else-if="user.name && defaultInitial"
          :data-initials="userInitials"
          class="profile-avatar"
          :style="{
            width: `${size}px`,
            height: `${size}px`,
            lineHeight: `${size}px`,
            clipPath: `circle(47% at 50%)`,
          }"
        />
        <img
          v-else
          alt="Profilbild"
          class="profile-avatar"
          :class="{ filtershadow: withshadow }"
          src="~/assets/images/dummyProfile.png"
          :style="{ clipPath: `circle()` }"
        />
      </div>
      <redDotSvg
        v-if="withIndicator"
        class="profile-pic-indicator"
      />
    </div>
  </div>
</template>

<script>
import redDotSvg from '../../assets/images/redDot.svg'

export default {
  components: {
    redDotSvg,
  },

  props: {
    withIndicator: {
      type: Boolean,
      required: false,
      default: false,
    },
    user: {
      type: Object,
      required: false,
      default: undefined,
    },
    size: {
      type: Number,
      required: false,
      default: 36,
    },
    withshadow: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultInitial: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  computed: {
    userInitials() {
      const out = this.user.name.match(/\b\w/g).join('').toUpperCase()
      if (out.length > 2) {
        return out.substring(0, 2)
      } else {
        return out
      }
    },
    imageApiRequest() {
      return `/api/profile-picture/${this.user.username}`
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
@import '../../assets/style/vars';

[data-initials]:before {
  background: #deeb75;
  color: white;
  opacity: 1;
  content: attr(data-initials);
  display: inline-block;
  border-radius: 50%;
  vertical-align: middle;
  margin-right: 0.5em;
  width: 100%;
  height: 100%;
  text-align: center;
  padding-bottom: 2px;
}

.profile-pic-container {
  position: relative;
  margin: 0 auto;
}

.border-blue {
  background: $darkblue;
}

.profile-avatar {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  left: 0;
}

.profile-pic-indicator {
  color: $red;
  height: 12px;
  width: 12px;
  position: absolute;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  margin-top: -12px;
  left: 50%;
}

.filtershadow {
  filter: drop-shadow(-1px 6px 3px #e5e5e5);

  &:hover {
    transform: scale(1.01);
  }
}
</style>
