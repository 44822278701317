<template>
  <div>
    <a
      v-if="isExternalLink"
      :href="$attrs.to"
      :class="className"
    >
      <slot />
    </a>
    <a
      v-else-if="$store.state.widget"
      :href="linkWidget"
      :class="className"
      target="_blank"
    >
      <slot />
    </a>
    <nuxt-link
      v-else
      v-bind="$attrs"
      :class="className"
    >
      <slot />
    </nuxt-link>
  </div>
</template>

<script>
import NuxtLink from '../.nuxt/components/nuxt-link.client.js'
export default {
  component: {
    NuxtLink,
  },
  props: {
    ...NuxtLink.props,
    className: {
      type: String,
      default: '',
    },
  },
  computed: {
    isExternalLink() {
      return this.$attrs.to.startsWith('http')
    },
    linkWidget() {
      let widgetOrigin = this.$config.widgetOrigin
      if (window.location.hostname.includes('localhost')) {
        widgetOrigin = widgetOrigin.replace('8000', '3000')
      }
      return widgetOrigin + this.$attrs.to
    },
  },
}
</script>
